angular.module('aerosApp')
	.constant("reportOptions", {
		labelMap: {
			// filter types
			"fiberType": "Media Type",
			"mpoType": "MPO",
			"fiberGroup": "Fiber Group",
			"testType": "Test Type",
			"resultType": "Results",
			// filter values
			"All": "All",
			"SMF": "SMF",
			"OS1": "OS1",
			"OS2": "OS2",
			"MMF": "MMF",
			"OM1": "OM1",
			"OM2": "OM2",
			"OM3": "OM3",
			"OM4": "OM4",
			"OM5": "OM5",
			"MPO": "MPO",
			"No MPO": "No MPO",
			"loss": "Loss",
            "length": "Length",
			"inspection": "Inspection",
			"PassedOrComplete": "Passed/Complete",
            "Failed": "Failed",
			"Incomplete": "Incomplete",
			"Active": "Active",
            "Archived": "Archived",
			"pdf": "PDF",
			"xlsx": "XLSX",
			"xls": "XLS",
            "csv": "CSV",
			"sor": "SOR",
            // report properties & sections
			"reportName": "Select Output",
			"ProjectFiberReport": "Loss and Inspection Detail",
			"ProjectFiberReportSummary": "Loss Summary",
			"Otdr_Report": "OTDR LinkMap & Events Report",
			"ExportReport": "Loss Data",
			"InspectionReport": "Inspection Detail",
			"InspectionReportCompact": "Inspection Report Summary",
       		"showCustomerContact": "Show Customer Contact",
     		"showConsultantContact": "Show Consultant Contact",
     		"showInstallerContact": "Show Installer Contact",
     		"showTeamMembers": "Show Team Members",
     		// column names – NOT CURRENT
            "fibergroup_name": "Fiber Group",
            "fiberType": "Media Type",
            "fiberMode": "Media Mode",
            "fiber_id": "Fiber Number",
            "fiber_Status": "Fiber Status",
            "endMainName": "Main Name",
            "endRemoteName": "Remote Name",
            "loss_wavelength": "Wavelength",
            "loss_mainToRemoteLoss": "Main-to-Remote Loss",
            "loss_mainToRemoteStatus": "Main-to-Remote Status",
			"loss_mainToRemoteFailedLimitsCsv": "Main-to-Remote Failed Limits",
            "loss_remoteToMainLoss": "Remote-to-Main Loss",
            "loss_remoteToMainStatus": "Remote-to-Main Status",
            "loss_remoteToMainFailedLimitsCsv": "Remote-to-Main Failed LImits",
            "loss_averageLoss": "Average Loss",
            "length": "Length",
            "roundedLength": "Rounded Length",
            "length_status": "Length Status",
            "length_failedLimitsCsv": "Length Failed Limits CSV",
			"inspection_mainToRemoteStandard": "Inspection Main-to-Remote Standard",
            "inspection_mainToRemoteStandardLabel": "Inspection Main-to-Remote Label",
            "inspection_mainToRemoteStatus": "Inspection Main-to-Remote Status",
            "inspection_mainToRemoteFailedLimitsCsv": "Inspection Main-to-Remote Failed Limits",
            "inspection_mainToRemoteImageUrl": "Inspection Main-to-Remote Image URL",
            "inspection_mainToRemoteOverlayImageUrl": "Inspection Main-to-Remote Overlay Image URL",
            "inspection_mainToRemoteFilename": "Inspection Main-to-Remote File Name",
            "inspection_mainToRemoteOverlayFilename": "Inspection Main-to-Remote Overlay File Name",
            "inspection_remoteToMainStandard": "Inspection Remote-to-Main Standard",
            "inspection_remoteToMainStandardLabel": "Inspection Remote-to-Main Standard Label",
            "inspection_remoteToMainStatus": "Inspection Remote-to-Main Status",
            "inspection_remoteToMainFailedLimitsCsv": "Inspection Remote-to-Main Failed Limits",		              
            "inspection_remoteToMainImageUrl": "Inspection Remote-to-Main Image URL",
            "inspection_remoteToMainOverlayImageUrl": "Inspection Remote-to-Main Overlay Image URL",
            "inspection_remoteToMainFilename": "Inspection Remote-to-Main File Name",
            "inspection_remoteToMainOverlayFilename": "Inspection Remote-to-Main Overlay File Name",
            "fiber_sortIndex": "Fiber Sort Index"
        },
		outputAllowed: {
			"Otdr_Report":["pdf"],
			"ProjectFiberReportSummary": ["pdf"],
			"ProjectFiberReport": ["pdf"],
			"ExportReport": ["xlsx", "xls", "csv"],
            "CustomLabeledReport": [{value:"sor", caption:"SOR File Export", reportType: 'SOR_Report'}],
			"InspectionReport": ["pdf"],
			"InspectionReportCompact": ["pdf"]
		},
        outputAllowedRequiredToolData: {
            "Otdr_Report": [
                ["OTDR"],
                ["OTDR", "OLTS"],
                ["OTDR", "Certification"],
                ["OTDR", "Inspection"],
                ["OTDR", "OLTS", "Inspection"],
                ["OTDR", "Certification", "Inspection"],
                ["OTDR", "OLTS", "Inspection", "Certification"]
            ],
            "ProjectFiberReportSummary": [
                ["OLTS"],
                ["Certification"],
                ["OLTS", "Inspection"],
                ["Certification", "Inspection"],
                ["OLTS", "OTDR"],
                ["Certification", "OLTS"],
                ["Certification", "OTDR"],
                ["OTDR", "OLTS", "Inspection"],
                ["OTDR", "Certification", "Inspection"],
                ["OLTS", "Inspection", "Certification"],
                ["OTDR", "OLTS", "Inspection", "Certification"]
            ],
            "ProjectFiberReport": [
                ["OLTS"],
                ["Certification"],
                ["Inspection"],
                ["OLTS", "Inspection"],
                ["Certification", "OLTS"],
                ["Certification", "Inspection"],
                ["OLTS", "OTDR"],
                ["Certification", "OTDR"],
                ["OTDR", "Inspection"],
                ["OTDR", "OLTS", "Inspection"],
                ["OTDR", "Certification", "Inspection"],
                ["OLTS", "Inspection", "Certification"],
                ["OTDR", "OLTS", "Inspection", "Certification"]
            ],
            "InspectionReport": [
                ["Inspection"],
                ["OLTS", "Inspection"],
                ["Certification", "Inspection"],
                ["OTDR", "Inspection"],
                ["OTDR", "OLTS", "Inspection"],
                ["OTDR", "Certification", "Inspection"],
                ["OLTS", "Inspection", "Certification"],
                ["OTDR", "OLTS", "Inspection", "Certification"]
            ],
            "InspectionReportCompact": [
                ["Inspection"],
                ["OLTS", "Inspection"],
                ["Certification", "Inspection"],
                ["OTDR", "Inspection"],
                ["OTDR", "OLTS", "Inspection"],
                ["OTDR", "Certification", "Inspection"],
                ["OLTS", "Inspection", "Certification"],
                ["OTDR", "OLTS", "Inspection", "Certification"]
            ]
        },
        allowedTestTypeOptions: {
		    "OTDR": ["loss"],
            "Loss": ["loss", "length"],
            "Inspection": ["inspection"],
            "OTDRLoss": ["loss", "length"],
            "LossInspection": ["loss", "length", "inspection"],
            "OTDRInspection": ["loss", "inspection"],
            "OTDRLossInspection": ["loss", "length", "inspection"]
        },
		filters: {
			fiberType: [
				            {value: "All", label: "All"}, // "All" option removed before submission, empty array means no filter
							{value: "SMF", label: "SMF"},
							{value: "OS1", label: "OS1"},
							{value: "OS2", label: "OS2"},
							{value: "MMF", label: "MMF"},
							{value: "OM1", label: "OM1"},
							{value: "OM2", label: "OM2"},
							{value: "OM3", label: "OM3"},
							{value: "OM4", label: "OM4"}
					],
			mpoType: [		
				            {value: "All", label: "All"},
							{value: "MPO", label: "MPO"},
							{value: "No MPO", label: "No MPO"}
			          ],
			fiberGroup: [	
			             	{value: "All", label: "All"} // ids + names populated per project
			             ],
			testType: [
				            {value: "All", label: "All"},
							{value: "loss", label: "Loss"},
				            {value: "length", label: "Length"},
							{value: "inspection", label: "Inspection"}
			           ],
			resultType: [	
				            {value: "All", label: "All"},
							{value: "PassedOrComplete", label: "Passed/Complete"},
				            {value: "Failed", label: "Failed"},
							{value: "Incomplete", label: "Incomplete"}
			             ],
			projectType: [
				            {value: "All", label: "All"},
							{value: "Active", label: "Active"},
				            {value: "Archived", label: "Archived"}
			              ]
		},
		report: {
			reportName: [
							{value: "ProjectFiberReport", label: "Group by Fiber"},
							{value: "ExportReport", label: "Custom Spreadsheet"},
							{value: "CustomLabeledReport", label: "CustomLabeledReport"}
			             ],
			output: [
							{value: "pdf", label: "PDF"},
							{value: "xlsx", label: "XLSX"},
							{value: "xls", label: "XLS"},
				            {value: "csv", label: "CSV"},
				            {value: "sor", label: "SOR"}
					],
       		showCustomerContact: [
  				            {value: true, label: "Customer"}
       		                      ],
       		showConsultantContact: [
  				            {value: true, label: "Consultant"}
     		                      ],
       		showInstallerContact: [
 				            {value: true, label: "Installer"}
     		                      ],
       		showTeamMembers: [
				            {value: true, label: "Team Members"}
 		                      ]
		},
		hideColumns: [
		              //{value: "organizationName", label: "All"},
		              //{value: "projectName", label: "All"},
		              //{value: "projectState", label: "All"},
		              //{value: "projectCompleted", label: "All"},
		              //{value: "projectFiberCount", label: "All"},
				              {value: "fibergroup_name", label: "Fiber Group"},
				              {value: "fiberType", label: "Media Type"},
				              {value: "fiberMode", label: "Media Mode"},
				              {value: "fiber_id", label: "Fiber Number"},
				              {value: "fiber_Status", label: "Fiber Status"},
		              //{value: "fiberCount", label: "All"},
				              {value: "endMainName", label: "Main Name"},
				              {value: "endRemoteName", label: "Remote Name"},
		              //{value: "hasLengthMeasurement", label: "All"},
		              //{value: "hasLossMeasurement", label: "All"},
		              //{value: "hasInspectionMeasurement", label: "All"},
				              {value: "loss_wavelength", label: "Wavelength"},
				              {value: "loss_mainToRemoteLoss", label: "Main-to-Remote Loss"},
				              {value: "loss_mainToRemoteStatus", label: "Main-to-Remote Status"},
		          			  {value: "loss_mainToRemoteFailedLimitsCsv", label: "Main-to-Remote Failed Limits"},
				              {value: "loss_remoteToMainLoss", label: "Remote-to-Main Loss"},
				              {value: "loss_remoteToMainStatus", label: "Remote-to-Main Status"},
				              {value: "loss_remoteToMainFailedLimitsCsv", label: "Remote-to-Main Failed LImits"},
				              {value: "loss_averageLoss", label: "Average Loss"},
				              {value: "length", label: "Length"},
				              {value: "roundedLength", label: "Rounded Length"},
				              {value: "length_status", label: "Length Status"},
				              {value: "length_failedLimitsCsv", label: "Length Failed Limits CSV"},
		
		          			  {value: "inspection_mainToRemoteStandard", label: "Inspection Main-to-Remote Standard"},
				              {value: "inspection_mainToRemoteStandardLabel", label: "Inspection Main-to-Remote Label"},
				              {value: "inspection_mainToRemoteStatus", label: "Inspection Main-to-Remote Status"},
				              {value: "inspection_mainToRemoteFailedLimitsCsv", label: "Inspection Main-to-Remote Failed Limits"},
				              {value: "inspection_mainToRemoteImageUrl", label: "Inspection Main-to-Remote Image URL"},
				              {value: "inspection_mainToRemoteOverlayImageUrl", label: "Inspection Main-to-Remote Overlay Image URL"},
				              {value: "inspection_mainToRemoteFilename", label: "Inspection Main-to-Remote File Name"},
				              {value: "inspection_mainToRemoteOverlayFilename", label: "Inspection Main-to-Remote Overlay File Name"},
		
				              {value: "inspection_remoteToMainStandard", label: "Inspection Remote-to-Main Standard"},
				              {value: "inspection_remoteToMainStandardLabel", label: "Inspection Remote-to-Main Standard Label"},
				              {value: "inspection_remoteToMainStatus", label: "Inspection Remote-to-Main Status"},
				              {value: "inspection_remoteToMainFailedLimitsCsv", label: "Inspection Remote-to-Main Failed Limits"},		              
				              {value: "inspection_remoteToMainImageUrl", label: "Inspection Remote-to-Main Image URL"},
				              {value: "inspection_remoteToMainOverlayImageUrl", label: "Inspection Remote-to-Main Overlay Image URL"},
				              {value: "inspection_remoteToMainFilename", label: "Inspection Remote-to-Main File Name"},
				              {value: "inspection_remoteToMainOverlayFilename", label: "Inspection Remote-to-Main Overlay File Name"},
		              //{value: "organizationId", label: "All"},
		              //{value: "projectUuid", label: "All"},
		              //{value: "fiberGroup_uuid", label: "All"},
				              {value: "fiber_sortIndex", label: "Fiber Sort Index"}
		          ],
        fiberGroupFilterItemType: {}
	});
